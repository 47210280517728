<template>
  <div>
    <GenericTable
      ref="lista"
      name="Médicos"
      title="Médicos"
      add-route="medicoAdicionar"
      edit-route="medicoEditar"
      :cols="['Nome', 'CRM', 'Telefone']"
      :cols-name="['nome', 'crm', 'telefone']"
      :cols-map="i => [i.nome, i.crm, i.telefone]"
      :route="medicoRoute"
      :permissionsToWrite="['rw_vinculo_externo']"
      idKey="id_medico"
      :hasPagination="true"
      :filters="filters"
      @clear-filters="clearFilters"
    >
      <template #filters>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Nome</label>
              <b-form-input
                v-model="filters.nome"
                autocomplete="off"
                class="invision-input sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>CRM</label>
              <b-form-input
                v-model="filters.crm"
                autocomplete="off"
                class="invision-input sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </GenericTable>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      medicoRoute: 'medico',
      filters: {
        nome: '',
        crm: '',
      },
    };
  },
  computed: {
    ...mapState(['filtersStored', 'previousRoute']),
  },
  created() {
    if (this.filtersStored.medico) {
      if (['medicoEditar'].includes(this.previousRoute.name)) {
        this.filters = { ...this.filtersStored.medico };
      } else {
        this.$store.commit('setFiltersStored', {});
      }
    }
  },
  methods: {
    clearFilters() {
      this.filters.nome = '';
      this.filters.crm = '';
    },
  },
};
</script>
